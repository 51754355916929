<template>
  <div class="callSettingContainer">
    <div class="call-setting-header">呼叫中心设置</div>
    <div class="call-setting">
      <div>服务器地址</div>
      <div class="value">
        <span>{{ server }}</span>
        <span @click="openUpdate('server')"><i class="el-icon-arrow-right"></i></span>
      </div>
    </div>
    <div class="call-setting">
      <div>集团号</div>
      <div class="value">
        <span>{{ vccId }}</span>
        <span @click="openUpdate('vccId')"><i class="el-icon-arrow-right"></i></span>
      </div>
    </div>
    <div class="call-setting">
      <div>sipXPath</div>
      <div class="value">
        <span>{{ sipXPath }}</span>
        <span @click="openUpdate('sipXPath')"><i class="el-icon-arrow-right"></i></span>
      </div>
    </div>
    <div class="call-setting">
      <div>通话告警时间</div>
      <div class="value">
        <span>{{ warningTime }}</span>
        <span @click="openUpdate('warningTime')"><i class="el-icon-arrow-right"></i></span>
      </div>
    </div>

    <el-dialog :title="title" width="20%" :visible.sync="show" :modal-append-to-body="false">
      <div class="dialog-container">
        <div class="title">请输入呼叫中心{{ title }}</div>
        <div class="text">
          <el-input v-model="cValue" placeholder="请输入"></el-input>
        </div>
        <div class="buttons">
          <el-button type="primary" @click="updateById">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CallBind",
  props: {},
  data() {
    return {
      title: "",
      server: "",
      vccId: "",
      sipXPath: "",
      warningTime: "",
      cValue: "",
      show: false,
      code: "",
      params: {},
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    list() {
      let url = `${this.baseUrls.callSetting}/queryList`;
      let params = {};
      this.topsAjax.post(url, params).then((reps) => {
        this.params = reps;
        this.server = reps["server"].cvalue;
        this.vccId = reps["vccId"].cvalue;
        this.sipXPath = reps["sipXPath"].cvalue;
        this.warningTime = reps["warningTime"].cvalue;
        console.log(reps);
      });
    },
    openUpdate(code) {
      if (code === "server") this.title = "服务器地址";
      else if (code === "vccId") this.title = "集团号";
      else if (code === "sipXPath") this.title = "sipXPath";
      else if (code === "warningTime") this.title = "通话告警时间";
      this.show = true;
      this.cValue = this.params[code].cvalue;
      this.code = code;
    },
    updateById() {
      let url = `${this.baseUrls.callSetting}/update`;
      let params = {
        sid: this.params[this.code].sid,
        cValue: this.cValue,
      };
      this.topsAjax.post(url, params).then((reps) => {
        this.show = false;
        this.list();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.callSettingContainer {
  height: 100%;
  width: 100%;
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  .call-setting-header {
    //width: 100%;
    height: 50px;
    line-height: 50px;

    padding: 0 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e4e7ed;
    font-size: 24px;
  }
  .call-setting {
    //width: 100%;
    height: 50px;
    line-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
    margin-left: 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e4e7ed;
  }
  .value {
    color: #909399;
    i {
      font-size: 18px;
    }
    i:hover {
      cursor: pointer;
    }
  }
  .dialog-container {
    .title {
      height: 30px;
      line-height: 30px;
    }
    .text {
      margin: 10px 0;
    }
    .buttons {
      height: 30px;
      line-height: 30px;
      text-align: right;
    }
  }
}
</style>
