var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "callSettingContainer" },
    [
      _c("div", { staticClass: "call-setting-header" }, [
        _vm._v("呼叫中心设置"),
      ]),
      _c("div", { staticClass: "call-setting" }, [
        _c("div", [_vm._v("服务器地址")]),
        _c("div", { staticClass: "value" }, [
          _c("span", [_vm._v(_vm._s(_vm.server))]),
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.openUpdate("server")
                },
              },
            },
            [_c("i", { staticClass: "el-icon-arrow-right" })]
          ),
        ]),
      ]),
      _c("div", { staticClass: "call-setting" }, [
        _c("div", [_vm._v("集团号")]),
        _c("div", { staticClass: "value" }, [
          _c("span", [_vm._v(_vm._s(_vm.vccId))]),
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.openUpdate("vccId")
                },
              },
            },
            [_c("i", { staticClass: "el-icon-arrow-right" })]
          ),
        ]),
      ]),
      _c("div", { staticClass: "call-setting" }, [
        _c("div", [_vm._v("sipXPath")]),
        _c("div", { staticClass: "value" }, [
          _c("span", [_vm._v(_vm._s(_vm.sipXPath))]),
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.openUpdate("sipXPath")
                },
              },
            },
            [_c("i", { staticClass: "el-icon-arrow-right" })]
          ),
        ]),
      ]),
      _c("div", { staticClass: "call-setting" }, [
        _c("div", [_vm._v("通话告警时间")]),
        _c("div", { staticClass: "value" }, [
          _c("span", [_vm._v(_vm._s(_vm.warningTime))]),
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.openUpdate("warningTime")
                },
              },
            },
            [_c("i", { staticClass: "el-icon-arrow-right" })]
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "20%",
            visible: _vm.show,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-container" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("请输入呼叫中心" + _vm._s(_vm.title)),
            ]),
            _c(
              "div",
              { staticClass: "text" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入" },
                  model: {
                    value: _vm.cValue,
                    callback: function ($$v) {
                      _vm.cValue = $$v
                    },
                    expression: "cValue",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.updateById } },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }